<script>
import { storeToRefs } from 'pinia';
import { ref } from 'vue'
import { useSitesStore } from '../stores/sites.js';
import { useUserStore } from '@/stores/user';
//test
export default {
    name: "SitesView",
    setup() {
        const siteStore = useSitesStore();
        const fuzzySearch = ref('')
        const userStore = useUserStore()
        const {isAdmin} =  userStore

        return {
            siteStore,
            fuzzySearch,
            isAdmin
        }
    },
}
</script>
<template>
    <v-container id="sites">

        <v-row>
            <v-col cols="12">
                <v-btn class="w-100" prepend-icon="fa-solid fa-circle-plus" :to="{ name: 'Site-Create'}">Add New Site</v-btn>
            </v-col>

            <v-col cols="12">
                <v-text-field hide-details prepend-inner-icon="fas fa-magnifying-glass" label="Search..." v-model="fuzzySearch"></v-text-field>
            </v-col>
            <v-col v-if="!siteStore.getSites(fuzzySearch).length" cols="12" >
                <h4>No Sites to view</h4>
            </v-col>
            <v-col v-for="(site, key) in siteStore.getSites(fuzzySearch)" :key="key" cols="12">

                <v-card :title="site.name">

                    <v-card-text>
                        <v-row>
                            <v-col v-if="site.client_name" cols="12" class="d-flex"><v-icon-primary-red class="mr-3" icon="far fa-user"></v-icon-primary-red> <span>{{site.client_name}}</span></v-col>
                            <v-col v-if="site.address" cols="12" class="d-flex"><v-icon-primary-red class="mr-3" icon="far fa-map-location"></v-icon-primary-red><span>{{site.address}}</span></v-col>
                            <v-col v-if="site.notes" cols="12" class="d-flex"><v-icon-primary-red class="mr-3" icon="far fa-note"></v-icon-primary-red><span>{{site.notes}}</span></v-col>
                            <v-col cols="6" >
                                <v-btn class="w-100" prepend-icon="fas fa-clipboard-list-check" :to="{ name: 'Inspections', params: { site_id: site.id }}">Inspections</v-btn>
                            </v-col>
                            <v-col cols="6" >
                                <v-btn class="w-100"  prepend-icon="fas fa-location-dot" :to="{ name: 'Site', params: { site_id: site.id }}">Site Details</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
                <!-- <template v-empty>
                        <v-col v-for="index in 5" :key="index" cols="12">
                            <v-skeleton-loader type="article"></v-skeleton-loader>
                        </v-col>
                </template> -->
        </v-row>
    </v-container>
</template>
